import {
  Card as DefaultCard,
  RadioGroup as DefaultRadioGroup,
} from "@blueprintjs/core"

import styled from "@emotion/styled"

export const Container = styled.div<{ active: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;

  &:focus {
    outline-width: 0;
  }

  & > input {
    display: none;
  }

  &:after {
    content: "";
    display: ${(props) => (props.active ? "block;" : "none;")};
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    /* Colors.VIOLET4 at 70% transparency */
    background: #c174c1;
    border-radius: 5px;
    margin: 0 auto;
    z-index: 1;
  }
`

export const RadioGroup = styled(DefaultRadioGroup)`
  display: flex;
  justify-content: space-around;
  width: 100%;

  .bp4-control {
    margin: 0;
  }
`

export const Card = styled(DefaultCard)`
  position: relative;
  padding: 0;
  letter-spacing: 0.78px;
  box-shadow: none;
  background-color: transparent;

  .bp4-control {
    margin: 0;
  }

  .bp4-tag {
    margin-right: 13px;
  }

  .bp4-progress-bar {
    height: 4px;
    border-radius: 0px;
    .bp4-progress-meter {
      border-radius: 3px;
    }
  }

  .name {
    min-width: 0;
    .bp4-editable-text {
      min-width: 0px;
    }

    .bp4-editable-text-content {
      min-width: 0px !important;
    }

    .bp4-icon {
      visibility: hidden;
      margin-left: 8px;
    }

    &:hover {
      .bp4-icon {
        visibility: visible;
      }
    }
  }
`
