import type { AppProps } from "next/app"
import { ErrorReporter } from "app/config/services/error-reporting"
import "@blueprintjs/icons/lib/css/blueprint-icons.css"
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css"
import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css"
import "@blueprintjs/select/lib/css/blueprint-select.css"
import { css, Global } from "@emotion/core"
import UserEngagement from "app/user-engagement/"
import { UserHelpProvider } from "app/user-help/"
import { ConfigProvider } from "app/config"
import ToasterManager from "features/toaster"
import Head from "next/head"
import Router from "next/router"
import * as React from "react"
import SAErrorPage from "features/error/ErrorPage"

import APIProvider from "lib/api"

import NotificationsProvider from "features/NotificationsProvider"
import { AuthProvider } from "features/auth/withAuth"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

import "../styles/blueprint-overrides.scss"
import "../styles/utilities.scss"
import "../styles/blueprint-simplyagree.scss"
import UploadQueue from "features/transaction/id/UploadQueue"

function App({ Component, pageProps, router }: AppProps) {
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      Router.events.on("routeChangeComplete", UserEngagement.onPageChange)
    }
  }, [])

  return (
    <>
      <Global
        styles={css`
          *:focus {
            outline: none;
          }
        `}
      />
      <Head>
        <title>SimplyAgree | Making Transactions Simple</title>
      </Head>
      <ConfigProvider>
        <ErrorReporter FallbackComponent={SAErrorPage}>
          <APIProvider>
            <ToasterManager>
              <AuthProvider>
                <UserHelpProvider>
                  <DndProvider backend={HTML5Backend}>
                    <NotificationsProvider>
                      <Component router={router} {...pageProps} />
                      <UploadQueue />
                    </NotificationsProvider>
                  </DndProvider>
                </UserHelpProvider>
              </AuthProvider>
            </ToasterManager>
          </APIProvider>
        </ErrorReporter>
      </ConfigProvider>
    </>
  )
}

export default App
