import * as React from "react"

import useToaster from "helpers/toaster"
import { addRootEntitiesToAuthRep } from "helpers/mappings/findEntityForAuthRep"
import { groupByKey, mergeItem, reduceIntoKeyByValue } from "helpers/array"
import { removeUndefinedProperties } from "helpers/object"
import { apiClient, useAPI, useAPIMutation } from "lib/api"
import {
  addTransactionParticipant,
  deleteCustomFieldKey,
  deleteInvitation,
  deleteParticipant,
  deleteTask,
  deleteTransactionParticipant,
  getAssignments,
  getCustomFieldKeys,
  getDocuments,
  getItems,
  getSigningGroups,
  getSignatoryUploadTemplate,
  getTransaction,
  getTransactionDashboardSignatories,
  getTransactionElements,
  getTransactionParticipants,
  getTransactionReport,
  getTransactionMatrixReport,
  patchCustomFieldKey,
  postBulkCustomFieldKey,
  postInvitation,
  postInvitationAccept,
  postTransactionDownload,
  updateItem,
  updateTask,
  updateTransaction,
  getTransactionFeatureFlags,
  createItem,
  createTask,
  createTransactionElement,
  deleteTransactionElement,
  updatePartialTransactionElement,
  getSignatureBlockTemplates,
  bulkElementUpdate,
  getTasks,
  createNote,
  getNotes,
} from "helpers/api"

import { useCurrentUser } from "features/auth/withAuth"
import {
  FullAssignment,
  itemPageAssignments,
} from "features/transaction/id/hooks"

import {
  selectTransaction,
  selectAssignments,
  selectDocuments,
  selectItems,
  selectNotes,
  selectParticipants,
  selectSignatories,
  selectSigningGroups,
  selectTransactionElements,
  selectTasks,
} from "./selectors"

import withAssignmentsAsAuthRep from "./auth-rep-assignments"
import withDocumentDmsMetaData from "./document-dms-metadata"
import {
  UpdatableAPITransaction,
  UpdatableTransaction,
  encodeTransactionUpdate,
  decode as decodeTransaction,
} from "models/Transaction"
import {
  APIItem,
  CreatableItem,
  UpdatableItem,
  encodeItemCreate,
  encodeItemUpdate,
} from "models/Item"
import { CreatableTask, encodeTaskCreate } from "models/Task"
import {
  APIInvitation,
  CreatableInvitation,
  encodeCreatable as encodeInviation,
} from "models/Invitation"
import { TransactionDownloadOption } from "models/TransactionDownload"
import { useTransactionIdParam } from "helpers/params"
import { QueryOptions } from "models/QueryOptions"
import { PageV1 } from "models/Pages"
import {
  APITransactionCustomField,
  CreateTransactionCustomField,
  UpdatableTransactionCustomField,
  decodeCustomField,
  encodeCreateCustomField,
  encodeUpdateCustomField,
} from "models/InstaPageV2/CustomFields/TransactionCustomField"
import { APITask, encodeTaskUpdate, UpdatableTask } from "models/Task"
import {
  APITransactionElement,
  CreatableTransactionElement,
  TransactionElement,
  UpdatableTransactionElement,
  encodeTransactionElement,
  encodeUpdatableTransactionElement,
} from "models/TransactionElement"
import {
  APISignatureBlockTemplate,
  decodeSignatureBlockTemplate,
} from "models/InstaPageV2/SignatureBlockTemplate"
import updateTransactionElementsWithOrder, {
  UpdatableElementOrder,
  bulkUpdateTransactionElementsWithOrder,
} from "./update-transaction-elements-with-order"
import { CreatableNote, encodeNoteCreate } from "models/Note"

export function useLastVisitedTransaction(
  id: string,
  queryOptions: QueryOptions = {}
) {
  return useAPI(
    ["transactions", "last-visited", id],
    () => getTransaction(id),
    {
      ...queryOptions,
      select: (data) => (data ? decodeTransaction(data) : undefined),
    }
  )
}

export function useTransaction(id: string, queryOptions: QueryOptions = {}) {
  return useAPI(["transaction", id], () => getTransaction(id), {
    ...queryOptions,
    select: (data) => (data ? selectTransaction(data) : undefined),
  })
}

export function getTransactionAssignments(id: string) {
  return selectAssignments(
    apiClient.getQueryData(["transactions", id, "assignments"])
  )
}

export function useTransactionAssignments(
  id: string,
  queryOptions: QueryOptions = {}
) {
  return useAPI(["transactions", id, "assignments"], () => getAssignments(id), {
    select: selectAssignments,
    ...queryOptions,
  })
}
export function getTransactionDocuments(id: string) {
  return selectDocuments(
    apiClient.getQueryData(["transactions", id, "documents"])
  )
}

export function useTransactionDocuments(
  id: string,
  queryOptions: QueryOptions = {}
) {
  return useAPI(["transactions", id, "documents"], () => getDocuments(id), {
    select: selectDocuments,
    ...queryOptions,
  })
}

export function getTransactionItems(id: string) {
  return selectItems(
    apiClient.getQueryData(["transactions", id, "items"]) || []
  )
}

export function useTransactionItems(
  id: string,
  queryOptions: QueryOptions = {}
) {
  return useAPI(["transactions", id, "items"], () => getItems(id), {
    select: selectItems,
    ...queryOptions,
  })
}

export function useTransactionTasks(
  id: string,
  queryOptions: QueryOptions = {}
) {
  return useAPI(["transactions", id, "tasks"], () => getTasks(id), {
    select: selectTasks,
    ...queryOptions,
  })
}

export function useTransactionElements(
  id: string,
  queryOptions: QueryOptions = {}
) {
  return useAPI(
    ["transactions", id, "transactionElements"],
    () => getTransactionElements(id),
    {
      select: selectTransactionElements,
      ...queryOptions,
    }
  )
}

export function useTransactionNotes(
  transactionId: string,
  queryOptions: QueryOptions = {}
) {
  return useAPI(
    ["transactions", transactionId, "notes"],
    () => getNotes(transactionId),
    {
      select: selectNotes,
      ...queryOptions,
    }
  )
}

export function useCreateTransactionElement(options = {}) {
  let transactionId = useTransactionIdParam()
  let { failure } = useToaster()
  let queryKey = ["transactions", transactionId, "transactionElements"]

  let result = useAPIMutation(
    (data: CreatableTransactionElement) =>
      createTransactionElement(encodeTransactionElement(data)),
    {
      onMutate: () => {
        let existingElements =
          apiClient.getQueryData<APITransactionElement[]>(queryKey) || []

        return { existingElements }
      },
      onSuccess: async () => {
        apiClient.refetchQueries(queryKey)
      },
      onError: (_, __, context) => {
        failure("There was a problem attempting to create the header")

        apiClient.setQueryData<APITransactionElement[]>(
          queryKey,
          context?.existingElements || []
        )
      },
      ...options,
    }
  )

  return {
    ...result,
    createTransactionElement: result.mutate,
    createTransactionElementAsync: result.mutateAsync,
  }
}

export function useUpdateTransactionElementOrder() {
  let transactionId = useTransactionIdParam()
  let { failure } = useToaster()
  let queryKey = ["transactions", transactionId, "transactionElements"]

  let result = useAPIMutation(
    (data: UpdatableElementOrder) =>
      updatePartialTransactionElement(transactionId, {
        uuid: data.id,
        order: data.order,
        parent_element: data.parentElement,
      }),
    {
      onMutate: (data) => {
        apiClient.setQueryData<APITransactionElement[]>(
          queryKey,
          (elements = []) => updateTransactionElementsWithOrder(data, elements)
        )
      },
      onSuccess: async () => {
        apiClient.refetchQueries(queryKey)
      },
      onError: () => {
        failure("There was a problem attempting to update the order")
      },
    }
  )

  return { ...result, updateTransactionElementOrder: result.mutate }
}
export function useBulkUpdateTransactionElementOrder() {
  let transactionId = useTransactionIdParam()
  let { failure } = useToaster()
  let queryKey = ["transactions", transactionId, "transactionElements"]

  let result = useAPIMutation(
    (data: { ids: string[]; order: number; parentElement: string | null }) =>
      bulkElementUpdate(data),
    {
      onMutate: (data) => {
        apiClient.setQueryData<APITransactionElement[]>(
          queryKey,
          (elements = []) =>
            bulkUpdateTransactionElementsWithOrder(data, elements)
        )
      },
      onSuccess: async () => {
        apiClient.refetchQueries(queryKey)
      },
      onError: () => {
        failure("There was a problem attempting to update the order")
      },
    }
  )

  return { ...result, bulkUpdateTransactionElementOrder: result.mutate }
}

export function useUpdateTransactionElement(options = {}) {
  let transactionId = useTransactionIdParam()
  let { failure } = useToaster()
  let queryKey = ["transactions", transactionId, "transactionElements"]

  let result = useAPIMutation(
    (data: UpdatableTransactionElement) =>
      updatePartialTransactionElement(
        transactionId,
        encodeUpdatableTransactionElement(data)
      ),
    {
      onMutate: (data) => {
        apiClient.setQueryData<APITransactionElement[]>(
          queryKey,
          (elements) =>
            elements?.map((element) => {
              if (element.uuid === data.id) {
                return {
                  ...element,
                  ...encodeUpdatableTransactionElement(data),
                }
              }
              return element
            }) || []
        )
      },
      onSuccess: async () => {
        apiClient.refetchQueries(queryKey)
      },
      onError: () => {
        failure("There was a problem attempting to update the header")
      },
      ...options,
    }
  )

  return { ...result, updateTransactionElement: result.mutateAsync }
}

export function useDeleteTransactionElement(options = {}) {
  let transactionId = useTransactionIdParam()
  let { failure } = useToaster()
  let queryKey = ["transactions", transactionId, "transactionElements"]

  let result = useAPIMutation(
    (data: TransactionElement) =>
      deleteTransactionElement(data.id, transactionId),
    {
      onSuccess: async () => {
        apiClient.refetchQueries(queryKey)
      },
      onError: () => {
        failure("There was a problem attempting to delete the header")
      },
      ...options,
    }
  )

  return { ...result, deleteTransactionElement: result.mutateAsync }
}

export function useTransactionFeatureFlags(id: string) {
  return useAPI(
    ["transactions", id, "feature-status"],
    () => getTransactionFeatureFlags(id),
    {
      select: (data) => data?.flags,
    }
  )
}

export function useTransactionParticipants(
  id: string,
  queryOptions: QueryOptions = {}
) {
  return useAPI(
    ["transactions", id, "participants"],
    () => getTransactionParticipants(id),
    {
      ...queryOptions,
      select: (data) => (data ? selectParticipants(data) : undefined),
    }
  )
}

export function getTransactionSignatories(id: string) {
  return selectSignatories(
    apiClient.getQueryData(["transactions", id, "signatories"])
  )
}

export function useTransactionSignatories(
  id: string,
  queryOptions: QueryOptions = {}
) {
  return useAPI(
    ["transactions", id, "signatories"],
    () => getTransactionDashboardSignatories(id),
    {
      ...queryOptions,
      select: selectSignatories,
    }
  )
}

export function useTransactionSignatureBlockTemplates(id: string) {
  return useAPI(["transactions", id, "signature-block-templates"], () =>
    getSignatureBlockTemplates(id)
  )
}

export function getTransactionSignatureBlockTemplates(id: string) {
  return (
    apiClient.getQueryData<APISignatureBlockTemplate[]>([
      "transactions",
      id,
      "signature-block-templates",
    ]) || []
  ).map(decodeSignatureBlockTemplate)
}

export function getTransactionSigningGroups(id: string) {
  return selectSigningGroups(
    apiClient.getQueryData(["transactions", id, "signing", "groups"]) || []
  )
}

export function useTransactionSigningGroups(
  id: string,
  queryOptions: QueryOptions = {}
) {
  return useAPI(
    ["transactions", id, "signing", "groups"],
    () => getSigningGroups(id),
    {
      ...queryOptions,
      select: selectSigningGroups,
    }
  )
}

export function useSignatoryUploadTemplate() {
  let transactionId = useTransactionIdParam()

  return useAPI(
    ["transactions", transactionId, "signatoryUploadTemplate"],
    () => getSignatoryUploadTemplate(transactionId)
  )
}

// DUPLICATE: features/manage-signatures/api
function useNotifyError(error: unknown) {
  const { failure } = useToaster()
  React.useEffect(() => {
    if (error) {
      failure("Failed to fetch transaction data")
    }
  }, [error])
}

export function useTransactionDashboard(
  id: string,
  queryOptions: QueryOptions = { staleTime: 10000 }
) {
  let transactionQuery = useTransaction(id, queryOptions)
  let itemsQuery = useTransactionItems(id)
  let tasksQuery = useTransactionTasks(id)
  let transactionElementsQuery = useTransactionElements(id)
  let notesQuery = useTransactionNotes(id)
  let assignmentsQuery = useTransactionAssignments(id)
  let signatoriesQuery = useTransactionSignatories(id)
  let signingGroupsQuery = useTransactionSigningGroups(id)
  let documentsQuery = useTransactionDocuments(id, { staleTime: 20000 })

  let queries = [
    transactionQuery,
    itemsQuery,
    tasksQuery,
    assignmentsQuery,
    signatoriesQuery,
    signingGroupsQuery,
    transactionElementsQuery,
    notesQuery,
  ]

  let anyError = queries.find((q) => q.error)?.error

  useNotifyError(anyError)

  let assignmentsById = reduceIntoKeyByValue(assignmentsQuery.data || [])

  let documentsById = React.useMemo(() => {
    return reduceIntoKeyByValue(documentsQuery.data || [])
  }, [documentsQuery.data])

  let items = itemsQuery.data || []

  let itemsById = reduceIntoKeyByValue(items)

  let signatories = signatoriesQuery.data

  let signingGroups = signingGroupsQuery.data || []

  let isLoadingInBackground =
    queries.every((q) => !q.isLoading) && queries.some((q) => q.isFetching)

  return {
    refetch: transactionQuery.refetch,
    error: anyError,
    isLoading: transactionQuery.isLoading || itemsQuery.isLoading,
    isLoadingInBackground,
    data: transactionQuery.data
      ? {
          ...transactionQuery.data,
          transactionElements: transactionElementsQuery.data,
          items: itemsQuery.data
            ? items.map((item) => ({
                ...item,
                dmsMetaData: withDocumentDmsMetaData(item, documentsById) || {},
                pages: item.pages.map((page) => ({
                  ...page,
                  assignments: assignmentsQuery.data
                    ? (page.assignments || [])
                        .map((assignmentId) => assignmentsById[assignmentId])
                        .filter((a) => !!a) // filter out orphaned assignment ids
                    : undefined,
                  items: (page as PageV1).items
                    ? (page as PageV1).items?.map((itemId) => itemsById[itemId])
                    : undefined,
                })),
              }))
            : undefined,
          tasks: tasksQuery.data,
          notes: notesQuery.data,
          signatories: signatories
            ? signatories.map((signatory) => ({
                ...signatory,
                groups: signingGroups.filter((group) =>
                  group.members.includes(signatory.id)
                ),
              }))
            : undefined,
        }
      : null,
  }
}

export function useTransactionCreatePackets(
  transactionId: string,
  queryOptions: QueryOptions = { staleTime: 10000 }
) {
  let transactionQuery = useTransaction(transactionId, queryOptions)
  let itemsQuery = useTransactionItems(transactionId, queryOptions)
  let transactionElementsQuery = useTransactionElements(
    transactionId,
    queryOptions
  )
  let assignmentsQuery = useTransactionAssignments(transactionId, queryOptions)
  let signatoriesQuery = useTransactionSignatories(transactionId, queryOptions)
  let signingGroupsQuery = useTransactionSigningGroups(
    transactionId,
    queryOptions
  )

  let queries = [
    transactionQuery,
    itemsQuery,
    assignmentsQuery,
    signatoriesQuery,
    signingGroupsQuery,
    transactionElementsQuery,
  ]

  let assignmentsBySignatoryId = React.useMemo(
    () =>
      groupByKey(
        itemPageAssignments(itemsQuery.data, assignmentsQuery.data).map(
          (assignment: FullAssignment) => ({
            ...assignment,
            // use the authRep if available, otherwise use the signatory
            signatoryId: assignment.authRep || assignment.signatory.id,
          })
        ),
        "signatoryId"
      ),
    [itemsQuery.data, assignmentsQuery.data]
  )

  let signatoriesById = reduceIntoKeyByValue(signatoriesQuery.data || [])

  let isAnyLoading = queries.some((q) => q.isLoading)

  let data = React.useMemo(
    () =>
      isAnyLoading
        ? null
        : {
            items: itemsQuery.data || [],
            signatories:
              signatoriesQuery.data
                ?.map((signatory, _, signatories) =>
                  addRootEntitiesToAuthRep(
                    signatory,
                    signatories,
                    () => true /* Default include all entities */
                  )
                )
                .map((signatory) => ({
                  ...signatory,
                  assignments: [
                    ...(assignmentsBySignatoryId[signatory.id] || []),
                    ...withAssignmentsAsAuthRep(
                      signatory,
                      assignmentsBySignatoryId
                    ),
                  ].map((assignment) => ({
                    ...assignment,
                    // NOTE Populate the assignment signatory object which could be
                    // the entity
                    signatory: signatoriesById[assignment.signatory.id],
                  })),
                })) || [],
            signingGroups: signingGroupsQuery.data || [],
            transactionElements: transactionElementsQuery.data || [],
          },
    [
      isAnyLoading,
      itemsQuery.data,
      assignmentsBySignatoryId,
      signatoriesQuery.data,
      signingGroupsQuery.data,
    ]
  )

  let result = React.useMemo(
    () => ({
      isLoading: isAnyLoading,
      data,
      error: queries.find((q) => q.error),
    }),
    [isAnyLoading, data, queries.find((q) => q.error)]
  )

  return result
}

export function useItemPageAssignments(transactionId: string) {
  let itemsQuery = useTransactionItems(transactionId)
  let assignmentsQuery = useTransactionAssignments(transactionId)

  let result = React.useMemo(
    () => itemPageAssignments(itemsQuery.data, assignmentsQuery.data),
    [itemsQuery.data, assignmentsQuery.data]
  )

  return result
}

export function getTransactionCustomFieldKeys(transactionId: string) {
  return (
    apiClient
      .getQueryData<APITransactionCustomField[]>([
        "transactions",
        transactionId,
        "custom-key",
      ])
      ?.map(decodeCustomField) || []
  )
}

export function useGetCustomFieldKeys(transactionId: string = "") {
  let transactionIdParam = useTransactionIdParam()
  let transactionIdtoUse = transactionId || transactionIdParam
  return useAPI(
    ["transactions", transactionIdtoUse, "custom-key"],
    () => getCustomFieldKeys(transactionIdtoUse),
    {
      select: (data) => (data ? data.map(decodeCustomField) : undefined),
    }
  )
}

/******************************************************************************
 * MUTATIONS
 ******************************************************************************/

export function useUpdateTransactionCM(
  options: {
    onSuccess?: (data: UpdatableAPITransaction | null) => void
    onError?: () => void
  } = {}
) {
  let result = useAPIMutation(
    (
      data: UpdatableTransaction & {
        shouldValidateWithDMS: boolean
      }
    ) => {
      let options: Record<string, boolean> = data.shouldValidateWithDMS
        ? {
            validate_cm: true,
          }
        : {}
      return updateTransaction(data.id, encodeTransactionUpdate(data), options)
    },
    {
      onSuccess: async (response) => {
        apiClient.refetchQueries(["transaction", response?.uuid], {
          exact: true,
        })
        // Will refetch the organizations transaction activity list
        apiClient.refetchQueries(["organizations"])
      },
      ...options,
    }
  )

  return { ...result, updateTransactionCM: result.mutateAsync }
}

export function useUpdateTransaction(options?: any) {
  // TODO-TS: Create type for options
  let result = useAPIMutation(
    (data: UpdatableTransaction) =>
      updateTransaction(data.id, encodeTransactionUpdate(data)),
    {
      onSuccess: async (response: UpdatableAPITransaction) => {
        await apiClient.cancelQueries(["transaction", response.uuid], {
          exact: true,
        })
        await apiClient.refetchQueries(["transaction", response.uuid], {
          exact: true,
        })
      },
      ...options,
    }
  )

  return { ...result, updateTransaction: result.mutateAsync }
}

export function useCreateItem(options = {}) {
  let transactionId = useTransactionIdParam()
  let { failure } = useToaster()
  let itemsQuery = ["transactions", transactionId, "items"]
  let elementQueryKey = ["transactions", transactionId, "transactionElements"]

  let result = useAPIMutation(
    (data: CreatableItem) => createItem(encodeItemCreate(data)),
    {
      onSuccess: async () => {
        await apiClient.refetchQueries(itemsQuery)
        await apiClient.refetchQueries(elementQueryKey)
      },
      onError: () => {
        failure("There was a problem attempting to create the document")
      },
      ...options,
    }
  )

  return { ...result, createItem: result.mutateAsync }
}

export function useUpdateItem(transactionId: string, options = {}) {
  let { failure } = useToaster()
  let queryKey = ["transactions", transactionId, "items"]

  let result = useAPIMutation(
    (data: UpdatableItem) => updateItem(data.id, encodeItemUpdate(data)),
    {
      onMutate: async (item) => {
        await apiClient.cancelQueries(queryKey)

        let prevItems = apiClient.getQueryData(queryKey)

        apiClient.setQueryData(queryKey, (items) =>
          mergeItem(
            items as APIItem[],
            removeUndefinedProperties({
              ...item,
              uuid: item.id,
              is_supplemental: item.isSupplemental,
            }),
            "uuid"
          )
        )

        return { prevItems }
      },
      onError: (_, __, context) => {
        failure("There was a problem attempting to update the document")

        apiClient.setQueryData(
          queryKey,
          context && context.prevItems ? context.prevItems : []
        )
      },
      onSettled: (item) => {
        apiClient.refetchQueries(queryKey)
        apiClient.refetchQueries(["items", item?.uuid])
      },
      ...options,
    }
  )

  return { ...result, updateItem: result.mutateAsync }
}

export function useCreateTask(options = {}) {
  let transactionId = useTransactionIdParam()
  let { failure } = useToaster()
  let tasksQuery = ["transactions", transactionId, "tasks"]
  let elementQueryKey = ["transactions", transactionId, "transactionElements"]

  let result = useAPIMutation(
    (data: CreatableTask) => createTask(encodeTaskCreate(data)),
    {
      onSuccess: async () => {
        await apiClient.refetchQueries(tasksQuery)
        await apiClient.refetchQueries(elementQueryKey)
      },
      onError: () => {
        failure("There was a problem attempting to create the task")
      },
      ...options,
    }
  )
  return { ...result, createTask: result.mutateAsync }
}

export function useCreateNote(options = {}) {
  let transactionId = useTransactionIdParam()
  let { failure } = useToaster()
  let queryKey = ["transactions", transactionId, "notes"]

  let result = useAPIMutation(
    (data: CreatableNote) => createNote(encodeNoteCreate(data)),
    {
      onSuccess: async () => {
        apiClient.refetchQueries(queryKey)
      },
      onError: () => {
        failure("There was a problem attempting to create the note")
      },
      ...options,
    }
  )

  return {
    ...result,
    createNote: result.mutate,
  }
}

export function useUpdateTask(options = {}) {
  let transactionId = useTransactionIdParam()
  let { failure } = useToaster()
  let queryKey = ["transactions", transactionId, "tasks"]
  let elementQueryKey = ["transactions", transactionId, "transactionElements"]

  let result = useAPIMutation(
    (data: UpdatableTask) => updateTask(data.id, encodeTaskUpdate(data)),
    {
      onMutate: (data) => {
        apiClient.setQueryData<APITask[]>(
          queryKey,
          (tasks) =>
            tasks?.map((task) => {
              if (task.uuid === data.id) {
                return {
                  ...task,
                  ...encodeTaskUpdate(data),
                }
              }
              return task
            }) || []
        )
      },
      onSuccess: async () => {
        apiClient.refetchQueries(queryKey)
        apiClient.refetchQueries(elementQueryKey)
      },
      onError: () => {
        failure("There was a problem attempting to update the task")
      },
      ...options,
    }
  )

  return { ...result, updateTask: result.mutateAsync }
}

export function useDeleteTask(transactionId: string, options = {}) {
  let { failure } = useToaster()
  let queryKey = ["transactions", transactionId, "tasks"]
  let elementQueryKey = ["transactions", transactionId, "transactionElements"]

  let result = useAPIMutation((id: string) => deleteTask(id), {
    onMutate: async (id) => {
      await apiClient.cancelQueries(queryKey)

      let prevTasks = apiClient.getQueryData(queryKey)

      apiClient.setQueryData(
        queryKey,
        (tasks: APITask[] | undefined) =>
          tasks?.filter((task) => task.uuid !== id) || []
      )

      return { prevTasks }
    },
    onError: (_, __, context) => {
      failure("There was a problem attempting to delete the task")

      if (context) {
        apiClient.setQueryData(queryKey, context.prevTasks)
      }
    },
    onSettled: () =>
      Promise.all([
        apiClient.refetchQueries(queryKey),
        apiClient.refetchQueries(elementQueryKey),
      ]),
    ...options,
  })

  return { ...result, deleteTask: result.mutateAsync }
}

export function useUpdateParticipants(transactionId: string) {
  let { failure, success } = useToaster()
  let { currentUser } = useCurrentUser()

  if (!currentUser) {
    throw new Error("No current user")
  }

  async function updateParticipants(
    existing: { id: string; userId: string }[] = [],
    updatedParticipants: { id: string }[] = []
  ) {
    let existingIds = existing.map(({ id }) => id)
    let newIds = updatedParticipants.map(({ id }) => id)

    let toDelete = existing
      .filter(({ id }) => !newIds.includes(id))
      .map((participant) =>
        deleteTransactionParticipant(
          String(currentUser?.organization),
          transactionId,
          participant.userId
        )
      )

    let toAdd = updatedParticipants
      .filter(({ id }) => !existingIds.includes(id))
      .map((participant) =>
        addTransactionParticipant(
          String(currentUser?.organization),
          transactionId,
          participant.id
        )
      )

    try {
      await Promise.all([Promise.all(toDelete), Promise.all(toAdd)])
      success("Participants successfully updated")
    } catch (e) {
      failure("Something went wrong attempting to update participants")
    }
    apiClient.refetchQueries(["organizations"])
  }

  return { updateParticipants }
}

export function useCreateInvitation(
  options: {
    onSuccess?: (data: APIInvitation | null) => void
    onError?: () => void
  } = {}
) {
  return useAPIMutation(
    (data: CreatableInvitation) => postInvitation(encodeInviation(data)),
    {
      onSuccess: (d) => {
        apiClient.refetchQueries(["transaction", d?.transaction])
        options.onSuccess?.(d)
      },
      onError: options.onError,
    }
  )
}

export function useAcceptInvitation(
  options: {
    onSuccess?: () => void
    onError?: () => void
  } = {}
) {
  return useAPIMutation((token: string) => postInvitationAccept(token), {
    onError: options.onError,
    onSuccess: () => {
      apiClient.refetchQueries(["transactions", "index"])
      options.onSuccess?.()
    },
  })
}

export function useCreateSignerStatusReport(transactionId: string) {
  return useAPIMutation(() => getTransactionReport(transactionId))
}

export function useCreateSigningMatrixReport(transactionId: string) {
  return useAPIMutation(() => getTransactionMatrixReport(transactionId))
}

export function useTransactionDownload(transactionId: string) {
  return useAPIMutation((data: { kind: TransactionDownloadOption }) =>
    postTransactionDownload(transactionId, data)
  )
}

export function useDeleteParticipant(
  options: {
    onSuccess?: () => void
    onError?: () => void
  } = {}
) {
  let transactionId = useTransactionIdParam()

  return useAPIMutation((id: string) => deleteParticipant(id), {
    onSuccess: () => {
      apiClient.refetchQueries(["transaction", transactionId])
    },
    onError: options.onError,
  })
}

export function useDeleteInvitation(
  options: {
    onSuccess?: () => void
    onError?: () => void
  } = {}
) {
  let transactionId = useTransactionIdParam()

  return useAPIMutation((id: string) => deleteInvitation(id), {
    onSuccess: () => {
      apiClient.refetchQueries(["transaction", transactionId])
    },
    onError: options.onError,
  })
}

export function useCreateBulkCustomField(
  options: {
    onSuccess?: () => void
    onError?: () => void
  } = {}
) {
  let transactionId = useTransactionIdParam()

  return useAPIMutation(
    (data: CreateTransactionCustomField[]) =>
      postBulkCustomFieldKey(data.map(encodeCreateCustomField)),
    {
      onSuccess: () => {
        apiClient.removeQueries([
          "transactions",
          transactionId,
          "signatoryUploadTemplate",
        ])
        apiClient.refetchQueries(["transactions", transactionId, "custom-key"])
        options.onSuccess?.()
      },
    }
  )
}

export function useUpdateCustomField(
  options: {
    onSuccess?: () => void
    onError?: () => void
  } = {}
) {
  let transactionId = useTransactionIdParam()

  return useAPIMutation(
    (data: UpdatableTransactionCustomField) =>
      patchCustomFieldKey(encodeUpdateCustomField(data)),
    {
      onSuccess: () => {
        apiClient.removeQueries([
          "transactions",
          transactionId,
          "signatoryUploadTemplate",
        ])
        apiClient.refetchQueries(["transactions", transactionId, "custom-key"])
        options.onSuccess?.()
      },
      onError: options.onError,
    }
  )
}

export function useDeleteCustomField(
  options: {
    onSuccess?: () => void
    onError?: () => void
  } = {}
) {
  let transactionId = useTransactionIdParam()

  return useAPIMutation((id: string) => deleteCustomFieldKey(id), {
    onSuccess: () => {
      apiClient.removeQueries([
        "transactions",
        transactionId,
        "signatoryUploadTemplate",
      ])
      apiClient.refetchQueries(["transactions", transactionId, "custom-key"])
    },
    onError: options.onError,
  })
}
