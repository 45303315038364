/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react"
import { useRouter } from "next/router"

import { ErrorClient } from "app/config/services/error-reporting"

import { http } from "helpers/http"
import { AuthContext } from "helpers/contexts"
import { getOrg } from "helpers/api"

import UserEngagement from "app/user-engagement"
import { decode as decodeClosingBinderStructure } from "models/ClosingBinderFormatStructure"

import Auth from "./"
import Delayed from "components/util/Delayed"
import FullPageSpinner from "components/spinners/fullPageSpinner"

import { User } from "models/User"
import { useAppConfig } from "app/config"

function getUser(config: { shouldLogout?: boolean } = {}) {
  return http.get({ url: `/authentication/user/me`, config })
}

export function useCurrentUser({ fetchUser = true } = {}) {
  const [isLoading, setIsLoading] = useState(false)
  const { user, refreshUser } = useContext(AuthContext)
  const [currentUser, setCurrentUser] = useState<User | null>(null)

  useEffect(() => {
    if (!user && fetchUser) {
      setIsLoading(true)
      getUser({ shouldLogout: false })
        .then((me) => {
          setCurrentUser(me)
          setIsLoading(false)
        })
        .catch(() => setIsLoading(false))
    }
  }, [user])

  return { currentUser: currentUser || user, isLoading, refreshUser }
}

function withAuth(Component: React.ComponentType<any>) {
  return function AuthComponent({ ...props }) {
    const { user, refreshUser } = useContext(AuthContext)

    if (!user || user.loadStatus !== "ORG") {
      return (
        <Delayed ms={100}>
          <FullPageSpinner />
        </Delayed>
      )
    }

    return <Component {...props} user={user} refreshUser={refreshUser} />
  }
}

const UnathenticatedPaths = [
  "/editor",
  "/login",
  "/login/set-password",
  "/register",
  "/contact",
  "/testdrive",
  "/transaction/embed",
  "/transaction/embed/auth",
]

type AuthProviderProps = {
  children?: React.ReactNode
}

export function AuthProvider({ children }: AuthProviderProps) {
  const { pathname } = useRouter()
  const [user, setUser] = useState<User | undefined>(undefined)
  const { fetchFeatureStatusAndUpdateConfig } = useAppConfig()

  async function logoutAsync(reason?: string | object) {
    let action =
      typeof reason === "string"
        ? reason
        : typeof reason === "object"
          ? "logout"
          : "inactive"
    let params = new URLSearchParams({ action })

    return Auth.signOut().then(() => {
      ErrorClient.clearUser?.()
      window.Beacon?.("logout")
      window.location.assign(`/login?${params}`)
    })
  }

  async function loadUser() {
    return http
      .get({ url: `/authentication/user/me` })
      .then((me) => {
        if (me) {
          setUser((prev) => ({
            ...me,
            // NOTE User is allowed to send packets by default,
            // if assigned to org that has esign turned off, we will set
            // to 'false' after org data is loaded below
            canSendPackets: true,
            // NOTE There are pieces of data for the user that are only included on
            // the org so code in the app can use the 'loadStatus' field to
            // determine if the user & org have been loaded, vs just the user.
            // We also don't want to overwrite existing loadStatus if refreshUser
            // is called
            loadStatus: prev?.loadStatus || "USER",
            shouldShowSurvey: true,
            shouldHideSupport: false,
            hasDMS: !!(
              me.fusion_url ||
              me.net_docs_config ||
              me.imanage_configs?.length > 0
            ),
            dmsType: me.net_docs_config
              ? "NET_DOCS"
              : me.imanage_configs?.length > 0
                ? "IMANAGE"
                : me.fusion_url
                  ? "SEE_UNITY"
                  : "NONE",
            formatStructure: me.binder_format_structure
              ? decodeClosingBinderStructure(me.binder_format_structure)
              : null,
          }))

          fetchFeatureStatusAndUpdateConfig()

          try {
            ErrorClient.setUser(me.uuid)
            ErrorClient.addMetadata("env", {
              hostname: window.location.origin,
            })

            UserEngagement.identifyUser(me.uuid, {
              created_at: new Date(me.created_at).getTime(),
              email: me.email,
              first_name: me.first_name,
              last_name: me.last_name,
            })
          } catch (e) {
            console.error("Error setting up user notifiers")
            console.error(e)
          }

          return me
        }
        throw "User not authenticated"
      })
      .then(async (user) => {
        if (user.organization) {
          let org = await getOrg(user.organization)

          if (!org) {
            return user
          }

          setUser((existing) => {
            if (!existing) return

            // Keep existing user data from, but overwrite org data
            let newUser = { ...existing }

            newUser.beacon_signature = existing?.beacon_signature || null
            newUser.shouldShowSurvey = !org?.disable_user_survey
            newUser.canSendPackets = !org?.disable_signature_application
            newUser.shouldHideSupport = !!org?.custom_help_url
            newUser.loadStatus = "ORG"
            newUser.organizationData = org || undefined

            return newUser
          })
        } else {
          setUser((userWithoutOrg) => {
            if (!userWithoutOrg) return

            // Keep existing user data from, but overwrite org data
            let updatedUser = { ...userWithoutOrg }
            updatedUser.loadStatus = "ORG"

            return updatedUser
          })
        }
      })
      .catch((err) => {
        if (user) {
          console.log(
            "LOGIN-DEBUG: error, has user - logging out with logoutAsync"
          )
        }
        let action = err?.status === 401 ? "logout" : "error"

        if (action === "logout") {
          return logoutAsync(action)
        }
      })
  }

  async function refreshUser() {
    await loadUser()
  }

  useEffect(() => {
    if (pathname === "/login" && user) {
      setUser(undefined)
    }

    if (!UnathenticatedPaths.includes(pathname) && !user) {
      loadUser()
    }
  }, [pathname, user])

  return (
    <AuthContext.Provider value={{ user, logoutAsync, refreshUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export default withAuth
