import {
  type APIInvitation,
  type Invitation,
  decode as decodeInvitation,
} from "./Invitation"
import {
  type APIClosingBinderDownload,
  type ClosingBinderDownload,
  decode as decodeClosingBinderDownload,
} from "./ClosingBinderDownload"
import {
  type APIParticipant,
  type Participant,
  decode as decodeParticipant,
} from "./Participant"

export type APITransaction = {
  action_required?: string
  archived_files_url?: string
  archived: boolean
  client_name: string
  client_number: string
  closing_binder?: APIClosingBinderDownload | null
  created_at: string
  created_by: {
    id: number
    name: string
  } | null
  deleted_participants?: APIParticipant[]
  description: string
  distributed_at: string | null
  expired_at?: string
  is_deed_transaction: boolean
  is_participant?: boolean
  is_sample: boolean
  kind: string // "packets" ... ???
  matter_number: string
  name: string
  num_esignings_waiting_to_be_accepted: number
  num_executed_signatures: number
  num_signatures: number
  participants?: APIParticipant[]
  pk?: string
  signing_instructions: string
  total_closing_binders: number
  total_signatures: number
  transaction_invitations: null | APIInvitation[]
  url: string
  uuid: string
}

export type Transaction = {
  actionRequired?: string
  archived: boolean
  archivedFilesUrl?: string
  clientName: string
  clientNumber: string
  closingBinder: ClosingBinderDownload | null
  createdAt: string
  createdBy: {
    id: number
    name: string
  } | null
  deletedParticipants: Participant[]
  description: string
  distributedAt: string | null
  executedSignatures: number
  expiredAt: string | null
  id: string
  invitations: Invitation[]
  isDeedTransaction: boolean
  isSample: boolean
  kind: string
  matterNumber: string
  name: string
  outstandingSignatures: number
  participants: Participant[]
  signingInstructions: string
  totalClosingBinders: number
  totalSignatures: number
  url: string
}

export type CreatableAPITransaction = Pick<
  APITransaction,
  "name" | "client_number" | "matter_number"
> &
  Pick<
    Partial<APITransaction>,
    | "client_name"
    | "description"
    | "signing_instructions"
    | "is_deed_transaction"
    | "is_sample"
  >

export type CreatableTransaction = Pick<
  Transaction,
  "name" | "clientNumber" | "matterNumber"
> &
  Pick<
    Partial<Transaction>,
    | "clientName"
    | "description"
    | "signingInstructions"
    | "isDeedTransaction"
    | "isSample"
  >

export type UpdatableAPITransaction = Pick<APITransaction, "uuid" | "name"> &
  Pick<
    Partial<APITransaction>,
    | "client_name"
    | "client_number"
    | "matter_number"
    | "description"
    | "signing_instructions"
    | "is_deed_transaction"
    | "is_sample"
  >

export type UpdatableTransaction = Pick<Transaction, "id" | "name"> &
  Pick<
    Partial<Transaction>,
    | "clientName"
    | "clientNumber"
    | "matterNumber"
    | "description"
    | "signingInstructions"
    | "isDeedTransaction"
    | "isSample"
  >

export const decode = ({
  pk,
  uuid: id,
  name,
  created_at: createdAt,
  created_by: createdBy,
  client_name: clientName,
  client_number: clientNumber,
  is_sample: isSample,
  is_deed_transaction: isDeedTransaction,
  archived_files_url: archivedFilesUrl,
  action_required: actionRequired,
  expired_at: expiredAt,
  matter_number: matterNumber,
  num_signatures: numSignatures,
  total_signatures: totalSignatures,
  num_esignings_waiting_to_be_accepted: outstandingSignatures,
  num_executed_signatures: executedSignatures,
  closing_binder: closingBinder,
  transaction_invitations: invitations,
  participants,
  deleted_participants = [],
  total_closing_binders: totalClosingBinders,
  description,
  signing_instructions: signingInstructions,
  archived,
  url,
  distributed_at: distributedAt,
  kind,
}: APITransaction): Transaction => ({
  id: id || pk || name,
  name,
  archivedFilesUrl,
  actionRequired,
  createdAt,
  createdBy: createdBy || null,
  clientName,
  clientNumber,
  isDeedTransaction,
  expiredAt: expiredAt || null,
  matterNumber,
  totalSignatures: totalSignatures || numSignatures,
  outstandingSignatures,
  executedSignatures,
  closingBinder: closingBinder
    ? decodeClosingBinderDownload(closingBinder)
    : null,
  invitations: invitations?.map(decodeInvitation) || [],
  participants: participants?.map(decodeParticipant) || [],
  deletedParticipants: deleted_participants.map(decodeParticipant),
  totalClosingBinders,
  description,
  signingInstructions: signingInstructions || "",
  isSample: isSample,
  archived: archived || false,
  url,
  distributedAt: distributedAt || null,
  kind,
})

export const encodeTransactionCreate = (
  t: CreatableTransaction
): CreatableAPITransaction => ({
  name: t.name,
  client_name: t.clientName,
  client_number: t.clientNumber,
  matter_number: t.matterNumber,
  is_deed_transaction: t.isDeedTransaction,
  description: t.description,
  signing_instructions: t.signingInstructions,
  is_sample: t.isSample,
})

export const encodeTransactionUpdate = (
  t: UpdatableTransaction
): UpdatableAPITransaction => ({
  uuid: t.id,
  name: t.name,
  client_name: t.clientName,
  client_number: t.clientNumber,
  matter_number: t.matterNumber,
  is_deed_transaction: t.isDeedTransaction,
  description: t.description,
  signing_instructions: t.signingInstructions,
  is_sample: t.isSample,
})

export type TransactionSortOptions =
  | "sort"
  | "name"
  | "created_at"
  | "client_name"
  | "client_number"
  | "created_by__first_name,created_by__last_name"

export type APITransactionFeatureFlags = {
  flags?: {
    [key: string]: {
      is_active: boolean
      last_modified: string
    }
  }
  switches?: {
    [key: string]: {
      is_active: boolean
      last_modified: string
    }
  }
}
